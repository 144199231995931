/**
 *
 * Componente para carregamento de selects dependente de outros
 *
 *
 *  [construtor]
 *
 *  - aContainer: Objeto ou selector jQuery que possui componente principal
 *
 *
 * Utiliza os seguintes parâmetros:
 *
 *   [data-child-container]: selector do container que exibirá os resultados
 *   [data-params-name]: nome do parâmetro que será enviado a url
 *   [data-url]: url da requisição ajax que retorna os resultados
 *   [data-content=loading]: Loader para exibir durante a requisição ajax
 *   [data-parent]: (opcional) nome do container wrapper, util para conteúdo nested. default: body
 *
 *
 * O JSON retornado pela url deve ser:
 *
 * [ {id: ..., name: ...}, {id: ..., name: ...}, ... ]
 */

 export class DependentSelect {


  constructor(aContainer) {
    /* globals */

    this._domContainer = $(aContainer)
    this._url = this._domContainer.data('url')
    this._loader = this._domContainer.data('loader')
    this._domLoader = this._domContainer.find('[data-content=loading]')
    this._parentSelector = this._domContainer.data('parent') || 'body'
    this._domParentContainer = this._domContainer.closest(this._parentSelector)
    this._domResultsContainer = this._domParentContainer.find(this._domContainer.data('child-container'))
    this._cached = {}

    this._initEventHandlers()

    this._init()
  }

  // event handlers

  _initEventHandlers() {

    let self = this

    this._domContainer.on('change', function(aEvent) {
      // não disparamos o evento agora pois o dependente pode ter um item
      // selectionado, fazendo com que o change seja disparado duas vezes!
      aEvent.preventDefault()
      aEvent.stopPropagation()

      self._stopLoading();
      self._getResults();
    });

    this._domResultsContainer.on('change', function(aEvent) {
      // precisamo atualizar o 'data-value' do child.

      self._domResultsContainer.attr('data-value', self._domResultsContainer.val())
    });
  }

  // privates

  _startLoading() {
    this._domLoader.attr('data-loading', 'true');

    if (this._loader) {
      $(this._loader).toggle(true)
    }
  }

  _stopLoading() {
    this._domLoader.attr('data-loading', 'false');

    if (this._loader) {
      $(this._loader).toggle(false)
    }
  }

  _getResults() {
    var self = this,
        paramName = this._domContainer.data('param-name'),
        ignoreBlank = this._domContainer.data('ignore-blank'),
        paramValue = this._domContainer.val(),
        data = {};

    if (paramValue === '' && ignoreBlank) {
      this._clearResults();
      return;
    }

    data[paramName] = paramValue;

    let cacheKey = JSON.stringify(data)

    if (this._cached[cacheKey]) {
      self._showResults(self._cached[cacheKey])
    } else {

      $.ajax({
        url: this._url,
        data: data,
        beforeSend: function() {
          self._clearResults()
          self._startLoading()
        },
        success: function(aData) {
          self._cached[cacheKey] = aData
          self._showResults(self._cached[cacheKey])
        },
        complete: function() {
          self._stopLoading()
        }
      });
    }

  }

  _showResults(aData) {
    var container = this._domContainer,
      childContainer = this._domResultsContainer,
      data = aData,
      selectedValue = this._domResultsContainer.attr('data-value'),
      selectAllSelected = (selectedValue === undefined),
      options = this._getInitialOptions(selectAllSelected),
      hasSelection = false;

    $.each(data, function(index, result){
      var selected = (selectedValue === (result.id + '')) ? ' selected=selected' : '';

      hasSelection = hasSelection || selected

      options += '<option value="' + result.id + '"' + selected + '>' + result.name + '</option>';
    });

    childContainer.html(options)

    container.trigger('dependent-select:child:load')

    this._selectDefaultResult()

    // nós cancelamos o evento anterior pois poderia haver um valor selecionado
    // como não há, disparamos novamente.

    childContainer.trigger('change')
  }

  _getInitialOptions(aSelected) {
    var dependentSelectBlank = this._domResultsContainer.data('dependent-select-blank');

    if (dependentSelectBlank !== undefined) {
      var selected = (aSelected ? ' selected=selected' : '');

      return "<option value=''" + selected + ">" + dependentSelectBlank + "</option>";
    }

    return '';
  }

  _clearResults() {
    var childContainer = this._domResultsContainer

    // armazena o resultado selecionado para mantê-lo após o load.
    childContainer.data('value', childContainer.val())

    childContainer.val('').html('')
  }

  _selectDefaultResult() {
    var dependentSelectBlank = this._domResultsContainer.data('dependent-select-blank');

    if (dependentSelectBlank !== undefined) {
      // para que o select2 selecione a opção padrão do select filho
      this._domResultsContainer.trigger('change.select2');
    }
  }

  _init() {
    if (this._domContainer.length > 0) {
      this._getResults();
    }
  }
}
