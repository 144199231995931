/**
 * Utilitário com helpers para tratar de url, window.location, window.history,
 * etc.
 */


export class UrlHelper {

  /* constants */
  static QUERY_PARAMETERS_REGEX = new RegExp('([^?=&]+)(=([^&]*))?', 'g');

  /**
   * @constructor
   */
  constructor() {

  }

  /* public API */

  /**
   * Retorna um hash para os parametros da url (window.location.search).
   * Ex: http://minha.url?filtro1=a&filtro2=b
   *     > { 'filtro1': 'a', 'filtro1': 'b' }
   */
  getQueryParameters() {
    return this._getQueryParameters();
  }

  /**
   * Retorna um hashmap com os parametros mapeados da url passada
   */
  getUrlParams(aUrl) {
    return this._getUrlParams(aUrl);
  }

  /**
   * Atualiza os parametros da URL através de replaceState para não haver
   * reload da página.
   */
  updateUrlParams(aParams, aPushState, aStateData) {
    this._updateUrlParams(aParams, aPushState, aStateData);
  }

  /**
   * Atualiza a URL através de replaceState para não haver reload da página.
   */
  updateUrl(aUrl, aPushState, aStateData) {
    this._updateUrl(aUrl, aPushState, aStateData);
  }

  /**
   * Atualiza a hash da página corrente.
   */
  updateHash(aHash, aPushState, aStateData) {
    this._updateHash(aHash), aPushState;
  }

  /**
   * Retorna os valores do form como parâmetro da URL
   */
  getFormParameters(aForm) {
    return this._getFormParameters(aForm);
  }

  /**
   * verifica se o parametro está contido
   */
  paramExists(aResult, aParamName) {
    return this._paramExists(aResult, aParamName);
  }

  /**
   * mergeia parâmetros do form com os da url
   */
  mergedParams(aUrlParams, aFormParams) {
    return this._mergedParams(aUrlParams, aFormParams);
  }

  /* privates */

  _updateUrlParams(aParams, aPushState, aStateData) {
    var decodedParams = '?' + decodeURIComponent($.param(aParams));

    // atualiza a location sem reload e sem incrementar o history.

    this._updateUrl(decodedParams, aPushState, aStateData);
  }

  _updateUrl(aUrl, aPushState, aStateData) {
    if (aPushState) {
      window.history.pushState(aStateData, window.title, aUrl);
    } else {
      window.history.replaceState({}, window.title, aUrl);
    }

    // atualiza tag de open_graph para refletir a nova location.search

    $('meta[property="og:url"]').attr('content', window.location);
  }

  _updateHash(aHash, aPushState, aStateData) {
    // window.location = '#' + aHash;

    if (aPushState) {
      window.history.pushState(aStateData, window.title, aUrl);
    } else {
      window.history.replaceState({}, window.title, '#' + aHash);
    }
  }

  _getFormParameters(aForm) {
    var params = aForm.serializeArray();

    // temos que inserir um outro parâmetros pois a url será alterada (History
    // API) e alguns navegadores irão cachear o resultado do AJAX pela sua url.
    // Quando a API encontrar o cache com a mesma url, irá renderizar o json
    // resultado do AJAX e não a página original.
    params.push({ '__': '__' });
    return params;
  }

  _getUrlParams(aUrl) {
    var result = {},
        params = aUrl.slice(aUrl.indexOf('?') + 1).split('&');

    for (var i = 0; i < params.length; i++) {
      var param = params[i].split('=');

      result[param[0]] = param[1];
    }

    return result;
  }

  _getQueryParameters() {
    var queryString = window.location.search,
        params = {};

    queryString.replace(UrlHelper.QUERY_PARAMETERS_REGEX, function($0, $1, $2, $3) {
      params[$1] = $3;
    });

    return params;
  }

  _paramExists(aResult, aParamName) {
    for (var i = 0; i < aResult.length; i++) {
      var item = aResult[i];

      if (item.name === aParamName) {
        return true;
      }
    }

    return false;
  }

  _mergedParams(aUrlParams, aFormParams) {
    // temos que mergear os parâmetros atuais da url para permitir que a
    // mesma página tenha mais de uma FilterBar e que seus parâmetros sejam
    // mutuamente preservados.

    // aUrlParams é um hash com name: value dos parâmetros.
    // aFormParams é um array com [ {name: '...', value: '...'} dos parâmetros.
    // Devemos dar prioridade ao aForm, ou seja, vamos adicionar os aUrlParams
    // apenas se ainda não existirem em aFormParams.


    // XXX removendo atributos com nomes duplicados, exceto arrays `attr[]`, para
    // evitar duplicações como no caso de checkboxes do rails, que adiciona
    // <input name="attr" type="hidden" value="off">
    // <input name="attr" type="checkbox" value="on">
    //
    // IMPORTANT iteramos sobre o form de "trás para frente", seguindo comportamento da especificação
    // de <form>, que é a de enviar apenas o último parâmetro com valor definido.
    var result = [];

    for (var i = aFormParams.length - 1; i >= 0; i--) {
      var formParam = aFormParams[i],
          formParamName = formParam.name;

      // se não for "array", ou seja, se param não terminar com `[]`, então tem que ser único
      if (!/\[\]$/.test(formParamName)) {
        this._addParamUnlessExists(result, formParam.name, formParam.value)
      } else {
        result.push(formParam)
      }
    }

    // mergeando form params com url params, dando preferência para valores já em form params
    for (var paramName in aUrlParams) {
      var paramValue = aUrlParams[paramName];

      this._addParamUnlessExists(result, paramName, paramValue);
    }

    return result;
  }

  _addParamUnlessExists(aResult, aParamName, aParamValue) {
    if (this._paramExists(aResult, aParamName)) {
      return;
    }

    aResult.push({ name: aParamName, value: aParamValue });

    return aResult;
  }
}
