/*
 * Módulo para carregamento automatico dos DependentSelect.
 *
 * Utiliza os seguintes parâmetros:
 *
 *   <... data-toggle='dependent-select'>
 */

import { DependentSelect } from '../components/dependent-select/dependent-select'

$(function() {

  $('[data-toggle=dependent-select]:not(.ignore-select2)').each(function() {

    var mainContainer = $(this),
        dependentSelect = new DependentSelect(mainContainer);
  });

});
