/**
 * Componente responsável por controlar requisições ajax para a index de algum
 * recurso.
 *
 * A estrutura básica de um remote-content, deve ser:
 *
 *  .qualquer-coisa{'data-remote-content': 'identificador-de-seu-remote'}
 *
 *    ... algum remote form com filtros ou link, por exemplo. ambos devem ser
 *    do tipo 'remote'..
 *
 *    .outra-classe{'data-remote-content-result': 'identificador-de-seu-remote'}
 *
 *      = render 'index' # as requisições remotas só irão renderizar o conteúdo
 *                       # da partial _index. se sua view inicial não carrega
 *                       # nada, esse render é desnecessário e assim que o
 *                       # 'index' é carregado, o remote-content é invocado para
 *                       # exibir o conteúdo.
 *
 */

import { DomHelper } from '../utils/dom-helper'
import { UrlHelper } from '../utils/url-helper'

export class RemoteContent {

  constructor(aRemoteContentContainer, aFilterBar) {

    let self = this

    this.remoteContentContainer = $(aRemoteContentContainer)
    this.domHelper = new DomHelper(this.remoteContentContainer)
    this.urlHelper = new UrlHelper()
    this.remoteContentId = this.remoteContentContainer.data('remote-content'),
    this.domResult = this.domHelper.findByData('remote-content-result', this.remoteContentId)
    this.filterBar = aFilterBar
    this.remoteContentContainer[0]._remoteContent = this

    /* event handlers */

    // o index será recarregado...

    this.remoteContentContainer.on('ajax:before', function() {

      self.domHelper.fireEvent('remote-content:before');

      self.urlHelper.updateUrlParams(self.getFormParameters(), false);

      self.startLoading();
    });

    this.remoteContentContainer.on('ajax:success', function(aEvent) {
      let data = aEvent.detail[2].response


      // escuta o retorno de um json ou html
      // se não é possível parsear o json o algoritmo entende que o data é html (catch)

      if ($(aEvent.target).data('remote-content') === 'ignore') {
        // temos que ignorar itens marcados para que possa ter ações remotas
        // dentro de um remote-content, como no caso dos favoritos.

        return;
      }

      try {
        var result = $.parseJSON(data);

        self.showJSONResults(result);
      } catch(e) {
        self.showResults(data);
      }

      self.domHelper.fireEvent('remote-content:after');

      if (self.afterContentCallback) {
        self.afterContentCallback()
      }
    });

    this.remoteContentContainer.on('ajax:error', function(aEvent) {
      self.showError(aEvent.detail[2] /* aXhr */, aEvent.detail[0] /* aError */);
      // Caso seja não autorizado, redirectionamos a página
    });

    // usuário clicou no cabeçalho de ordenação de uma tabela dentro do remote
    // content

    this.remoteContentContainer.on('click', 'th a[data-remote=true]', function(aEvent) {
      self.updateUrlAndFilterBarForm($(this).attr('href'));

    });

    // usuário clicou numa página de navegação para remote content

    this.remoteContentContainer.on('click', '.pagination a[data-remote=true]', function(aEvent) {
      self.updateUrlAndFilterBarForm($(this).attr('href'));
    });

    // usuário clicou em limpar filtro e um remote-content com filterbar...

    this.remoteContentContainer.on('click', '[data-input=clear-filter]', function() {
      if (self.filterBar !== undefined) {
        self.filterBar.clearFilter();
      }
    });

    /* setup */

    self.loadData();
  }

  /* privates */

  /** loading */

  startLoading() {
    this.remoteContentContainer.attr('data-loading', true);
    this.remoteContentContainer.attr('data-error', false);

    this.remoteContentContainer.find('[data-remote-content-partial]').attr('data-loading', true);
    this.remoteContentContainer.find('[data-remote-content-partial]').attr('data-error', false);
  };

  stopLoading() {
    this.remoteContentContainer.attr('data-loading', false);

    this.remoteContentContainer.find('[data-remote-content-partial]').attr('data-loading', false);
  }

  /** results */

  showResults(aData) {

    /* Precisamos extrair as 'partials' do resultado para que possam ser
     * inseridas em outra parte do DOM. Isso permite que o mesmo resultado
     * de uma requisição traga blocos de DOM (como totalizações, listas e etc.)
     * que podem ser inseridas de modo independente no DOM, sem necessariamente
     * ser no _domResult. Tudo que não estiver como 'partial' será inserido
     * normalmente no _domResult.
     */

    var data = $(aData),
        wrapper = $('<div>');

    wrapper.html(data);

    var partials = wrapper.find('[data-remote-content-partial-target]');

    partials.each(function() {
      var partial = $(this),
          targetId = partial.data('remote-content-partial-target'),
          target = $('[data-remote-content-partial="' + targetId + '"]');

      target.html(partial);
    });

    this.domResult.html(wrapper.html());
    this.enableSubmit();
    this.stopLoading();
  }

  showError(aXhr, aError) {
    // Caso seja não autorizado, redirectionamos para o path retornado.

    if (aXhr.status === 401) {
      var path = aXhr.responseJSON['redirect_to'];
      window.location = path;
      return;
    }

    if (aXhr.status === 500) {
      this.remoteContentContainer.attr('data-error', true);
    }

    this.stopLoading();
  }

  loadData() {
    let domRemoteContentForm = $(this.domHelper.find('form'))

    if (domRemoteContentForm.data('remote') === true) {
      Rails.fire(domRemoteContentForm[0], 'submit');
    } else {
      //XXX: pre-commit não permite console.lo(g)!
      // console.lo('Form precisa ser remoto. Adicione o data-remote: true.');
    }
  }

  updateUrlAndFilterBarForm(aUrl) {
    var params = this.urlHelper.getUrlParams(aUrl);

    this.urlHelper.updateUrlParams(params, true /* aPushState para adicionar nova entreada no histórico */);

    this.updateFilterBarForm(params);
  }

  updateFilterBarForm(aParams) {
    // temos que atualizar todos os campos marcados com 'data-remote-content-param'
    // para haver sincronia dos diversos parametros (paginacao, sort, etc...)

    var inputs = this.domHelper.find('[data-remote-content-param]');

    inputs.each(function() {
      var input = $(this),
          inputId = input.attr('id'),
          paramValue = aParams[inputId];

        input.val(paramValue);
    });
  }

  getFormParameters() {

    var urlParams = this.urlHelper.getQueryParameters();

    if (!this.urlHelper.paramExists(urlParams, '__')) {
      Object.assign(urlParams, {'__': '__'});
    }

    return urlParams;

  }

  /* Reabilita os botões de submits que possam ter disable-with.
   */
  enableSubmit() {
    var inputs = this.domHelper.find('input[type=submit]:disabled');

    inputs.prop('disabled', false);
  }

  /*
   * Recarrega dados (com seus filtros e parâmetros de page, sort, etc.) de
   * uma entreada no histórico.
   */
  popRemoteStateAndLoadData(aEvent) {
    // aEvent.target.location.search = parametros da url antiga que está sendo 'popped'
    // ex: ?cod_gestora=+&data_assinatura=&data_publicacao_portal=&decricao_modalidade=+&page=17&search=&sort_column=integration_supports_creditors.nome&sort_direction=desc&status=+&tipo_objeto=+
    var params = this.urlHelper.getUrlParams(aEvent.target.location.search);

    this.updateFilterBarForm(params);

    this.loadData();
  }

  /*
   *
   * Trata o retorno do JSON no form
   *
   */
  showJSONResults(aData) {
    this.enableSubmit();
    this.stopLoading();

    if (aData.status === 'success') {
      this.showJSONSuccess(aData);
    } else {
      this.showJSONErrors(aData);
    }
  }

  /*
   * Trata a validação do form
   *
   * Espera-se:
   * aData   =>    json: { status: string, errors: ActiveModel::Errors, message: [string] }
   */
  showJSONErrors(aData) {
    this.remoteContentContainer.find('[data-alert=error]').show();
    this.remoteContentContainer.find('[data-message=alert]').text(aData.message);

    // limpa todas as mensagens de erro dos inputs
    this.remoteContentContainer.find('[data-input-message]').text('');
    $.each(aData.errors, function(i, item) {
      this.remoteContentContainer.find('[data-input-message=' + i + ']').text(item[0]);
    });
  }

  /*
   * Trata o sucesso do form
   *
   * Espera-se:
   * aData   =>    json: { status: string, message: [string] }
   */
  showJSONSuccess(aData) {
    this.remoteContentContainer.find('[data-link=collapse]').siblings().andSelf().hide();
    this.remoteContentContainer.find('[data-alert=success]').show();
    this.remoteContentContainer.find('[data-message=success]').text(aData.message);
  }
}
