import { DomHelper } from '../utils/dom-helper'
import { UrlHelper } from '../utils/url-helper'
import { FilterBarFormHandler } from './filter-bar-form-handler'

/**
 * Componente responsável por barra de filtro simples.
 */

export class FilterBar {

    /* consts */

  static INPUT_CHANGE_SELECTORS = 'select, [data-remote-select2], input[type=checkbox], [data-filter-bar-field]';
  static DATE_TIME_INPUT_CHANGE_SELECTORS =  'input.datetimepicker, input.date-datetimepicker, input.month-datetimepicker, input.year-datetimepicker';
  static STATS_INPUT_CHANGE_SELECTORS = '[data-stats-month]';

  constructor(aFilterBarContainer) {
    /* globals */

    const self = this;

    this._domFilterBarContainer = aFilterBarContainer
    this._domHelper = new DomHelper(this._domFilterBarContainer)
    this._domFilterForm = this._domHelper.find('form')
    this._formHandler = new FilterBarFormHandler(this._domFilterBarContainer, this)
    this._urlHelper = new UrlHelper()
    this._initEventHandlers()

    aFilterBarContainer[0]._filterBar = this
  }

  /* public API */

  clearFilter() {
    this._formHandler.clearFilter()
  }

  getFilterBarId() {
    this._getFilterBarId()
  }

  submitForm() {
    this._formHandler.submitForm()
  }

  updateUrl() {
    this._formHandler.updateUrl()
  }

  getFormParameters() {
    return this._formHandler.getFormParameters()
  }

  /* event handlers */

  // history.back...

  _initEventHandlers() {

    let self = this

    window.addEventListener('popstate', function(aEvent) {
      // aEvent.target.location.search = parametros da url antiga que está sendo 'popped'
      // ex: ?cod_gestora=+&data_assinatura=&data_publicacao_portal=&decricao_modalidade=+&page=17&search=&sort_column=integration_supports_creditors.nome&sort_direction=desc&status=+&tipo_objeto=+

      var stateData = aEvent.state,
          filterBarId = (stateData && stateData['filter_bar_id']);

      if (filterBarId === self._getFilterBarId()) {
        var params = self._urlHelper.getUrlParams(aEvent.target.location.search);

        _restoreFormParameters(params);
      }

    }, true);

    // usuário clicou no botão de limpar o filtro...

    this._domFilterBarContainer.on('click', 'button[type=reset]', function() {
      self._formHandler.clearFilter();
    });

    // usuário mudou alguma opção de filtro em select...

    this._domFilterBarContainer.on('change', FilterBar.INPUT_CHANGE_SELECTORS, function() {
      if (self._considerInputChanged(this)) {
        self._formHandler.submitForm();
      }
    });

    // usuário alterou o fitro de estatísticas de período mensal

    this._domFilterBarContainer.on('change', FilterBar.STATS_INPUT_CHANGE_SELECTORS, function() {
      if (self._considerInputChanged(this)) {

        var inputChanged = $(this);

        new MonthRange(inputChanged).validate();

        self._formHandler.submitForm();
      }
    });

    // usuário mudou alguma data no bootstrap-datetimepicker

    this._domFilterBarContainer.on('dp.change', FilterBar.DATE_TIME_INPUT_CHANGE_SELECTORS, function() {
      if (self._considerInputChanged(this)) {

        self._formHandler.submitForm();
      }
    });

    // usuário mudou algum período no bootstrap-datetimepicker

    this._domFilterBarContainer.on('apply.daterangepicker', function() {
      if (self._considerInputChanged(this)) {
        self._formHandler.submitForm();
      }
    });

    // usuário pressionou enter no campo de busca...

    this._domFilterBarContainer.on('keyup', 'input[type=text]', function(aEvent) {
      self._formHandler.handleKeyUp(aEvent);
    });

  }

  /* privates */

  _considerInputChanged(aInput) {
    // temos que ignorar os changes enquanto estiver limpando filtros do select2
    // pois é necessário dar um trigger nessa merda de componente.
    if (this._formHandler.isClearingFilters()) {
      return false;
    }

    return ! ($(aInput).attr('data-filter-bar-ignore') !== undefined);
  }

  _getFilterBarId() {
    return this._domFilterBarContainer.data('filter-bar');
  }
}
