import { MaskManager } from './mask_manager'

/**
 * JavaScript com handlers padrão para todos os nested_forms
 */

$(function() {
'use strict';

  // globals

  var self = this,
      _maskManager = new MaskManager();

  // event_handlers

  $('form').on('cocoon:after-insert', _cocoonAfterInsert);

  $('form').on('cocoon:before-remove', _cocoonBeforeRemove);

  // privates

  /// cocoon handlers

  function _cocoonAfterInsert(aEvent, aElement) {

    // atualiza os componentes (máscara, plugins, ...) para o elemento inserido.

    _initMasks(aElement);

    _initPlugins(aElement);

    // atualiza visibilidade dos links (add, remove, etc...)

    _updateLinksVisibility(aElement);

    // feedback para o usuário perceber o item inserido.

    _highlightElement(aElement);

    _focusAndSelectFirstInput(aElement);
  }

  function _cocoonBeforeRemove(aEvent, aElement) {
    // atualiza visibilidade dos links (add, remove, etc...)

    _updateLinksVisibility(aElement, true);
  }

  /// ui

  function _highlightElement(aElement) {
    setTimeout(function() {
      // precisa do jquery-ui mas não vale a pena usar apenas pelo efeito.
      // $(aElement).highlight();
    }, 100);
  }

  function _focusAndSelectFirstInput(aElement) {
    // não usado pois seleciona apenas input, pulando o select.
  }

  /*
   * Atualiza os links do nested de acordo com as opções definidas.
   *
   * data-limit: limita a quantidade de items que podem ser inseridos e
   * esconde o botão de add.
   *
   */
  function _updateLinksVisibility(aElement, aRemoving) {
    _updateAddLinkVisibility(aElement, aRemoving);
  }

  function _updateAddLinkVisibility(aElement, aRemoving) {
    var element = $(aElement),
        nestedAddLink =_getNestedAddLink(element),
        limit = _getNestedLimit(element),
        offset = aRemoving ? -1 : 0,
        count = ( _getNestedCount(element) + offset ),
        visible = (count < limit);

    _setAddLinkVisibility(nestedAddLink, visible);
  }

  function _setAddLinkVisibility(aAddLink, aVisible) {
    aAddLink.attr('disabled', !aVisible);
    aAddLink.prop('disabled', !aVisible);
  }

  function _getNestedId(aElement) {
    return aElement.data('nested-field');
  }

  function _getNestedCount(aElement) {
    var parentNested = _getParentNested(aElement),
        elementNestedId = _getNestedId(aElement),
        nesteds = parentNested.find('[data-nested-field=' + elementNestedId + ']');

    return nesteds.length;
  }

  function _getNestedLimit(aElement) {
    var nestedAddLink =_getNestedAddLink(aElement);
    return ( (nestedAddLink.data('limit')) || +Infinity );
  }

  function _getNestedAddLink(aElement) {
    var parentNested = _getParentNested(aElement),
        elementNestedId = _getNestedId(aElement);

    return parentNested.find('[data-nested-field-add=' + elementNestedId + ']');
  }

  function _getParentNestedId(aElement) {
    return aElement.data('parent-nested');
  }

  function _getParentNested(aElement) {
    var parentNestedId = _getParentNestedId(aElement);

    return aElement.closest('[data-nested-field=' + parentNestedId + ']');
  }

  // retorna todos os containers de nested para que o link de 'add' possa
  // ser atualizado e considerar o data-limit, que permite definir um limite
  // de nested-fields inseridos.
  function _getNestedContainers() {
    return $('[data-nested-field]');
  }

  /// masks / plugins

  /*
   * Inicia máscaras dentro de um container, que é o nested adicionado.
   */
  function _initMasks(aContainer) {
    _maskManager.initMasks(aContainer);
  }

  /*
   * Inicia plugins dentro de um container específico.
   */
  function _initPlugins(aContainer) {
    _initDatePicker(aContainer);
  }

  function _initDatePicker(aContainer) {
    // new BootstrapDatePickerController(aContainer).init();
  }

  /// setup

  function _init() {
    var container = $(document),
        nestedContainers = _getNestedContainers();

    _initPlugins(container);

    for (var i = 0; i < nestedContainers.length; i++) {
      _updateLinksVisibility($(nestedContainers[i]));
    }
  }

  _init();
});
