/**
 * JavaScript com handlers padrão para todos os nested_forms
 */

$(function() {
'use strict';

  $('input.custom-file-input').on('change', function() {
    _updateInput($(this));
    _clearValidation($(this));
  });

  $('.custom-file-clear-button').on('click', function() {
    var input = $(this).closest('.custom-file').find('.custom-file-input');

    input.val('');

    _updateInput(input);
  });

  function _updateInput(aInput) {
    var hasValue = (aInput.val() !== '');

    aInput.closest('.custom-file').find('.custom-file-clear-button').toggle(hasValue);

    if (hasValue) {
      var filename = unescape(encodeURIComponent(aInput[0].files[0].name));

      aInput.next('.custom-file-label').text(filename);
    } else {
      aInput.next('.custom-file-label').text(aInput.data('original-label'));
    }
  }

  function _clearValidation(aInput) {
    aInput.removeClass('is-invalid');
  }

  function _init() {
    $('input.custom-file-input').each(function() {

      $(this).data('original-label', $(this).next('.custom-file-label').text());

      _updateInput($(this));
    });
  }

  _init();
});
