/**
 * Componente responsável pelo comportamento do form da barra de filtro.
 */

import { DomHelper } from '../utils/dom-helper'
import { EventKeyHelper } from '../utils/event-key-helper';
import { UrlHelper } from '../utils/url-helper'

export class FilterBarFormHandler {
  /* consts */

  static INPUT_CHANGE_SELECTORS = 'select, [data-remote-select2], input[type=checkbox], [data-filter-bar-field]';
  static DATE_TIME_INPUT_CHANGE_SELECTORS =  'input.datetimepicker, input.date-datetimepicker, input.month-datetimepicker, input.year-datetimepicker';
  static STATS_INPUT_CHANGE_SELECTORS = '[data-stats-month]';

  static INPUTS_SELECTOR = 'input[type=search], input:text:not(.datetimepicker):not(.month-datetimepicker):not(.year-datetimepicker):not([data-filter-bar-bypass-clear]), input:hidden:not([data-filter=permanent]):not([data-filter-bar-bypass-clear])';
  static CHECKBOXES_SELECTOR = 'input:checkbox:not([data-filter-bar-bypass-clear])';
  static SELECTS_SELECTOR = 'select:not([data-filter-bar-bypass-clear]), [data-remote-select2]:not([data-filter-bar-bypass-clear])';
  static DATETIMES_SELECTOR = 'input.datetimepicker:not([data-filter-bar-bypass-clear]), input.month-datetimepicker:not([data-filter-bar-bypass-clear]), input.year-datetimepicker:not([data-filter-bar-bypass-clear])';


  constructor(aFilterBarContainer, aFilterBar) {

    /* globals */

    this._domFilterBarContainer = aFilterBarContainer
    this._filterBar = aFilterBar
    this._domHelper = new DomHelper(this._domFilterBarContainer)
    this._domFilterForm = this._domHelper.find('form')
    this._urlHelper = new UrlHelper()
    this._eventKeyHelper = new EventKeyHelper()
    this._isClearingFilters = false

  }

  /* public API */

  clearFilter() {
    this._clearFilter();
  }

  isClearingFilters() {
    return this._isClearingFilters;
  }

  submitForm() {
    this._submitForm();
  }

  handleKeyUp(aEvent) {
    this._handleKeyUp(aEvent);
  }

  updateUrl() {
    this._updateUrl();
  }

  getFormParameters() {
    return this._getFormParameters()
  }

  /* privates */

  _clearFilter() {
    this._isClearingFilters = true
    this._resetForm();
    this._isClearingFilters = false

    this._submitForm();
  }

  _resetForm() {
    var inputs = this._domFilterForm.find(FilterBarFormHandler.INPUTS_SELECTOR),
        checkboxes = this._domFilterForm.find(FilterBarFormHandler.CHECKBOXES_SELECTOR),
        selects = this._domFilterForm.find(FilterBarFormHandler.SELECTS_SELECTOR),
        dateTimePickers = this._domFilterForm.find(FilterBarFormHandler.DATETIMES_SELECTOR);

    this._clearInput(inputs);
    this._clearCheckbox(checkboxes);
    this._clearSelect(selects);
    this._clearDateTimePickers(dateTimePickers);
  }

  _submitForm() {
    // atualiza a url na barra pois o load é via ajax...
    this._updateUrl()

    Rails.fire(this._domFilterForm[0], 'submit')
  }

  _getFormParameters() {
    var validInputs = this._domFilterForm.find('input[name!=flexdatalist-search_datalist], select'),
        formParams = this._getSerializedArrayFromInputs(validInputs),
        urlParams = this._urlHelper.getQueryParameters(),
        params =  this._urlHelper.mergedParams(urlParams, formParams);

    // temos que inserir um outro parâmetros pois a url será alterada (History
    // API) e alguns navegadores irão cachear o resultado do AJAX pela sua url.
    // Quando a API encontrar o cache com a mesma url, irá renderizar o json
    // resultado do AJAX e não a página original.
    if (!this._urlHelper.paramExists(params, '__')) {
      params.push({ '__': '__' });
    }

    return params;
  }

  _getSerializedArrayFromInputs(aInputs) {
    return Array.from(aInputs).map((aInput) => {
      if (aInput.type === 'checkbox') {
        return { name: aInput.name, value: (aInput.checked ? 'true' : '') }
      } else {
        return { name: aInput.name, value: aInput.value }
      }
    })
  }

  /*
   * Restaura os valores dos filtros baseado na URL. Usado no popstate do
   * histórico.
   */
  _restoreFormParameters(aParams) {
    var params = aParams,
        inputs = this._domFilterForm.find(':input');

    for (var i = 0; i < inputs.length; i++) {
      var input = $(inputs[i]),
          name = input.prop('name');

      if (aParams[name] !== undefined) {
        var paramValue = aParams[name],
            paramValueWithSpaces = (paramValue + '').replace(/\+/g, '%20'),
            decodedValue = decodeURIComponent(paramValueWithSpaces);

        if (input.data('select2') !== undefined) {
          // Altera o valor do select2 sem disparar o onchange que recarregaria
          // os dados!

          input.val(decodedValue);
          input.trigger('change.select2');
        } else if (input.prop('type') === 'checkbox') {
          input.prop('checked', decodedValue === input.prop('value'));
        } else {
          input.val(decodedValue);
        }
      }
    }
  }

  _handleKeyUp(aEvent) {
    var event = aEvent,
        keyCode = event.keyCode,
        input = $(event.target);

    if (this._eventKeyHelper.isEnterKeyCode(keyCode)) {
      if (input.data('filter-bar-ignore-submit-on-enter') === true) {
        // para campos de busca dentro de form e que possuem botão de submit,
        // temos que ignorar o enter pois o próprio rails faz a submissão e iria
        // 2 vezes.

        this._updateUrl();

        return;
      }

      this._submitForm();
    }

    if (this._eventKeyHelper.isEscKeyCode(keyCode)) {
      this._clearInput(input);
      this._submitForm();
    }

    aEvent.preventDefault();
    aEvent.stopPropagation();
  }

  _clearInput(aInput) {
    aInput.val('');
  }

  _clearCheckbox(aCheckbox) {
    aCheckbox.prop('checked', false);
  }

  _clearSelect(aSelect) {
    // jeito normal para limpar...
    aSelect.val('');

    // jeito select2 para limpar...
    aSelect.val(null).trigger('change');

    aSelect.find('option').prop('selected', false);
  }

  _clearDateTimePickers(aInput) {
    aInput.each(function() {
      var input = $(this),
        placeholderValue = input.attr('placeholder');

      input.data('DateTimePicker').date(null);
    });
  }

  _updateUrl() {
    var filterBarId = this._filterBar.getFilterBarId(),
        stateData = { filter_bar_id: filterBarId };

    this._urlHelper.updateUrlParams(this._getFormParameters(), true /* aPushState para adicionar nova entreada no histórico */, stateData);
  }
}
