$(function(){
'use strict';

  if ($.tab) {
    var url = document.location.toString();
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
        $('.nav-pills a[href="#' + url.split('#')[1] + '"]').tab('show');
    }

    $('.nav-tabs a, .nav-pills a').on('shown.bs.tab', function (e) {
      if(history.replaceState) {
        history.replaceState(null, null, e.target.hash);
      } else {
        window.location.hash = e.target.hash;
      }
    });

  }

});
